'use client';

import { useState } from 'react';

import homeHeaderImages from '../../constants/homeHeaderImages';
import GridControls from './GridControls';
import ImageGridContainer from './ImageGridContainer';
import MediaCard from './MediaCard';

function ImageGrid() {
  const [index, setIndex] = useState(0);
  const onPrevClick = () => {
    setIndex((index - 1 + homeHeaderImages.length) % homeHeaderImages.length);
  };
  const onNextClick = () => {
    setIndex((index + 1) % homeHeaderImages.length);
  };

  const { destination, mediaItems, url } = homeHeaderImages[index];
  const hasVideo = mediaItems.some((image) => image.isVideo);

  return (
    <ImageGridContainer
      hasVideo={hasVideo}
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
    >
      <MediaCard {...mediaItems[0]} onVideoEnd={onNextClick} />
      <MediaCard {...mediaItems[1]} onVideoEnd={onNextClick} variant="middle" />
      <MediaCard {...mediaItems[2]} onVideoEnd={onNextClick} variant="square" />
      <MediaCard {...mediaItems[3]} onVideoEnd={onNextClick} />
      <MediaCard {...mediaItems[4]} onVideoEnd={onNextClick} />
      <GridControls
        destination={destination}
        url={url}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
      />
    </ImageGridContainer>
  );
}

export default ImageGrid;
