'use client';

import clsx from 'clsx';
import { MouseEvent, ReactNode } from 'react';

import LoadingDots from 'common-toolbox/src/components/LoadingDots';

import { sendClickEvent } from '@withlocals/analytics';

import CSS from './Button.module.scss';

interface ButtonProps {
  as?: any;
  type?: 'button' | 'submit' | 'reset';
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
  className?: any;
  shape?: 'round' | 'rounded';
  trackingName?: string;
  [key: string]: any;
}

function Button({
  as: Component = 'button',
  type = 'button',
  size = 'medium',
  fullWidth,
  children,
  isLoading,
  disabled,
  shape = 'round',
  trackingName,
  className,
  ...props
}: ButtonProps) {
  const classes = clsx(className, CSS.Button, {
    [CSS.ButtonSmall]: size === 'small',
    [CSS.ButtonExtraSmall]: size === 'extraSmall',
    [CSS.ButtonMedium]: size === 'medium',
    [CSS.ButtonLarge]: size === 'large',
    [CSS.RoundedButton]: shape === 'rounded',
    [CSS.ButtonFullWidth]: fullWidth,
    [CSS.ButtonLoading]: isLoading,
  });

  const clickEventTracker = (event: MouseEvent<HTMLElement>) => {
    sendClickEvent({
      trackingName,
      type,
      href: props.href,
      text: event?.currentTarget.innerText,
    });

    return props?.onClick?.(event);
  };

  return (
    <Component
      type={type}
      className={classes}
      disabled={disabled || isLoading}
      onClick={props?.onClick ? clickEventTracker : undefined}
      {...props}
    >
      {!isLoading && children}
      {isLoading && <LoadingDots />}
    </Component>
  );
}

export default Button;
