'use client';

import Link from 'next/link';

import useIsMobile from '@/common/hooks/useMobile';

import CSS from './AutoCompleteSearchInput.module.scss';
import SearchInputForm from './SearchInputForm';

function AutoCompleteSearchInput({
  highlightedDestinations,
  isLocalSearch,
}: any) {
  const isMobile = useIsMobile();
  return (
    <div className={CSS.Container}>
      <Link
        href={`/search/?type=${isLocalSearch ? 'locals' : 'experiences'}`}
        className={CSS.Link}
      >
        where are you going?
      </Link>
      <SearchInputForm
        highlightedDestinations={highlightedDestinations}
        isLocalSearch={isLocalSearch}
        isDisabled={isMobile}
      />
    </div>
  );
}

export default AutoCompleteSearchInput;
