import CSS from '../../../../styles/icons/duoTone.module.scss';
import { DuoToneIconType } from '../../IconType';
import SVGIcon from '../../SVGIcon';

function BadgeWithStarIcon(props: DuoToneIconType) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M12.0004054,2.5 C17.1566211,2.5 21.3529556,6.60743427 21.4961241,11.7290179 L21.4999,11.9994946 C21.4999,17.2461703 17.247081,21.4989892 12.0004054,21.4989892 C6.75352131,21.4989892 2.4999,17.2459618 2.4999,11.9994946 C2.4999,6.75302739 6.75352131,2.5 12.0004054,2.5 Z M17.971883,10.5112135 C17.8934673,10.2580645 17.6687536,10.0860215 17.4147803,10.0860215 L13.7046639,10.0860215 L12.5565174,6.3797235 C12.3985156,5.8734255 11.6014843,5.8734255 11.4434825,6.3797235 L10.2953361,10.0860215 L6.58521969,10.0860215 C6.33124642,10.0860215 6.10653274,10.2580645 6.02811703,10.5112135 C5.95087171,10.7643625 6.03630972,11.0420891 6.24112687,11.1981567 L9.24316115,13.4887865 L8.09618509,17.1950845 C8.01893976,17.4494624 8.10437777,17.7259601 8.30919493,17.8820276 C8.51518246,18.0380952 8.79139302,18.0380952 8.99738056,17.8820276 L11.9994148,15.5913978 L15.0026194,17.8820276 C15.1056132,17.9606759 15.2249923,18 15.3467123,18 C15.4672618,18 15.5878113,17.9606759 15.6908051,17.8820276 C15.8956222,17.7259601 15.9810602,17.4494624 15.9026445,17.1950845 L14.7568389,13.4887865 L17.7588731,11.1981567 C17.9636903,11.0420891 18.0491283,10.7643625 17.971883,10.5112135"
        data-variant={props.variant}
        className={CSS.Background}
      />
      <path
        d="M12.0004054,1.5 C17.7993658,1.5 22.4999,6.20053423 22.4999,11.9994946 L22.4961261,12.2837879 C22.3454889,17.9513076 17.7043008,22.4989892 12.0004054,22.4989892 C6.20126781,22.4989892 1.4999,17.7982779 1.4999,11.9994946 C1.4999,6.20071138 6.20126781,1.5 12.0004054,1.5 Z M12.0004054,2.5 C6.75352131,2.5 2.4999,6.75302739 2.4999,11.9994946 C2.4999,17.2459618 6.75352131,21.4989892 12.0004054,21.4989892 C17.247081,21.4989892 21.4999,17.2461703 21.4999,11.9994946 L21.4961241,11.7290179 C21.3529556,6.60743427 17.1566211,2.5 12.0004054,2.5 Z M17.971883,10.5112135 C17.8934673,10.2580645 17.6687536,10.0860215 17.4147803,10.0860215 L13.7046639,10.0860215 L12.5565174,6.3797235 C12.3985156,5.8734255 11.6014843,5.8734255 11.4434825,6.3797235 L10.2953361,10.0860215 L6.58521969,10.0860215 C6.33124642,10.0860215 6.10653274,10.2580645 6.02811703,10.5112135 C5.95087171,10.7643625 6.03630972,11.0420891 6.24112687,11.1981567 L9.24316115,13.4887865 L8.09618509,17.1950845 C8.01893976,17.4494624 8.10437777,17.7259601 8.30919493,17.8820276 C8.51518246,18.0380952 8.79139302,18.0380952 8.99738056,17.8820276 L11.9994148,15.5913978 L15.0026194,17.8820276 C15.1056132,17.9606759 15.2249923,18 15.3467123,18 C15.4672618,18 15.5878113,17.9606759 15.6908051,17.8820276 C15.8956222,17.7259601 15.9810602,17.4494624 15.9026445,17.1950845 L14.7568389,13.4887865 L17.7588731,11.1981567 C17.9636903,11.0420891 18.0491283,10.7643625 17.971883,10.5112135"
        data-variant={props.variant}
        className={CSS.Outline}
      />
    </SVGIcon>
  );
}

export default BadgeWithStarIcon;
