export type MediaItemType = {
  cloudinaryId: string;
  isVideo?: boolean;
  alt: string;
};

export type HomeHeaderImageType = {
  destination: string;
  url: string;
  mediaItems: MediaItemType[];
};

const homeHeaderImages: HomeHeaderImageType[] = [
  {
    destination: 'Marrakech',
    url: '/experiences/morocco/marrakech/',
    mediaItems: [
      {
        cloudinaryId: 'website/homepage/image-grid/marrakech/marrakech-0',
        alt: 'Marrakech', // TODO: add alt text
      },
      {
        cloudinaryId: 'website/homepage/image-grid/marrakech/marrakech-1',
        alt: 'Marrakech',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/marrakech/marrakech-2',
        alt: 'Marrakech',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/marrakech/marrakech-3',
        alt: 'Marrakech',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/marrakech/marrakech-4',
        alt: 'Marrakech',
      },
    ],
  },
  {
    destination: 'Kyoto',
    url: '/experiences/japan/kyoto/',
    mediaItems: [
      {
        cloudinaryId: 'website/homepage/image-grid/kyoto/kyoto-0',
        alt: 'Kyoto',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/kyoto/kyoto-1',
        alt: 'Kyoto',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/kyoto/kyoto-2',
        alt: 'Kyoto',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/kyoto/kyoto-3',
        alt: 'Kyoto',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/kyoto/kyoto-4',
        alt: 'Kyoto',
      },
    ],
  },
  {
    destination: 'Rome',
    url: '/experiences/italy/rome/',
    mediaItems: [
      {
        cloudinaryId: 'website/homepage/image-grid/rome/rome-1',
        alt: 'Rome',
      },
      {
        cloudinaryId: 'FOOD_TOUR_ROME_sftijk',
        isVideo: true,
        alt: 'Rome',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/rome/rome-2',
        alt: 'Rome',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/rome/rome-0',
        alt: 'Rome',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/rome/rome-4',
        alt: 'Rome',
      },
    ],
  },
  {
    destination: 'Bangkok',
    url: '/experiences/thailand/bangkok/',
    mediaItems: [
      {
        cloudinaryId: 'website/homepage/image-grid/bangkok/bangkok-1',
        alt: 'Bangkok',
      },
      {
        cloudinaryId: 'STREET_FOOD_GENERAL_flo9lo',
        isVideo: true,
        alt: 'Bangkok',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/bangkok/bangkok-2',
        alt: 'Bangkok',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/bangkok/bangkok-3',
        alt: 'Bangkok',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/bangkok/bangkok-4',
        alt: 'Bangkok',
      },
    ],
  },
  {
    destination: 'Barcelona',
    url: '/experiences/spain/barcelona/',
    mediaItems: [
      {
        cloudinaryId: 'website/homepage/image-grid/barcelona/barcelona-0',
        alt: 'Barcelona',
      },
      {
        cloudinaryId: '90min_General_Fin-1_spvvir',
        isVideo: true,
        alt: 'Barcelona',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/barcelona/barcelona-5',
        alt: 'Barcelona',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/barcelona/barcelona-3',
        alt: 'Barcelona',
      },
      {
        cloudinaryId: 'website/homepage/image-grid/barcelona/barcelona-4',
        alt: 'Barcelona',
      },
    ],
  },
];

export default homeHeaderImages;
