import clsx from 'clsx';
import { useRef } from 'react';
import { FocusEvent } from 'react';

import SearchGlassIcon from '@/common/components/icons/tools-items/full-color/SearchGlassIcon';

import CSS from './SearchInput.module.scss';

function SearchInput({
  onChange,
  onKeyDown,
  onInputFocus,
  disabled,
  isModal,
  isLocalSearch,
}: any) {
  const inputRef = useRef<HTMLInputElement>(null);
  const onSearchInput = (e: any) => {
    return onChange(e.target.value);
  };

  const onFocus = () => onInputFocus(true);
  const onBlur = (event: FocusEvent) => {
    if (event.relatedTarget?.getAttribute('data-role') === 'search-type') {
      inputRef.current?.focus();
    } else {
      setTimeout(() => onInputFocus(false), 200);
    }
  };
  const onSearchButtonClick = () => {
    setTimeout(() => inputRef?.current?.focus());
  };

  const classes = clsx(CSS.SearchInputContainer, {
    [CSS.CompactSearchInput]: isModal,
  });

  return (
    <div className={classes}>
      <input
        type="text"
        ref={inputRef}
        autoFocus={isModal}
        className={CSS.SearchInput}
        placeholder={
          isLocalSearch ? 'Find your local in...' : 'Where are you going?'
        }
        disabled={disabled}
        onChange={onSearchInput}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button
        className={CSS.SearchButton}
        type="submit"
        onClick={onSearchButtonClick}
      >
        <span className={CSS.IconContainer}>
          <SearchGlassIcon variant="dark-green" />
        </span>
        <span className={CSS.SearchButtonText}>Search</span>
      </button>
    </div>
  );
}

export default SearchInput;
