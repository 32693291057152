'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

import StarRating from '@/common/components/StarRating';
import ChevronIcon from '@/common/components/icons/symbols/full-color/ChevronIcon';

import HostAndGuestImages from '@/components/HostAndGuestImages';

import CSS from './Testimonial.module.scss';
import TestimonialMetaData from './TestimonialMetaData';

export interface TestimonialProps {
  title: string;
  review?: string;
  rating?: number;
  destination: string;
  date: string;
  local: {
    image: string;
    name: string;
    url: string;
  };
  guest: {
    image: string;
    name: string;
  };
  product: {
    sku: string;
    title: string;
  };
}

function Testimonial({
  title,
  review,
  rating = 5,
  local,
  destination,
  date,
  guest,
  product,
}: TestimonialProps) {
  const [showFullText, setShowFullText] = useState(false);
  const [isClamped, setIsClamped] = useState(true);
  const reviewRef = useRef<HTMLParagraphElement>(null);

  const onShowMore = () => setShowFullText(true);

  useEffect(() => {
    if (reviewRef?.current) {
      setIsClamped(
        reviewRef.current.scrollHeight > reviewRef.current.clientHeight,
      );
    }
  }, [reviewRef, setIsClamped]);

  return (
    <>
      <TestimonialMetaData
        title={title}
        review={review}
        rating={rating}
        local={local}
        destination={destination}
        date={date}
        guest={guest}
        product={product}
      />
      <li className={CSS.Testimonial}>
        <div className={CSS.RatingRow}>
          <span className={CSS.Rating}>{rating.toFixed(1)}</span>
          <StarRating rating={rating} size={14} />
        </div>
        <h3 className={CSS.Title}>
          {title || `${guest.name} about ${local.name}`}
        </h3>
        <div className={CSS.ReviewContainer}>
          <p
            className={clsx(CSS.Review, { [CSS.ClampedReview]: !showFullText })}
            ref={reviewRef}
          >
            {review ? `"${review}"` : null}
          </p>
          {isClamped && !showFullText && (
            <button onClick={onShowMore} className={CSS.ReadMoreButton}>
              Read more <ChevronIcon size={12} direction="right" />
            </button>
          )}
        </div>
        <div className={CSS.BottomRow}>
          <HostAndGuestImages local={local} guest={guest} />
          <div className={CSS.TextContainer}>
            <span className={CSS.GuestName}>{guest.name}</span>
            <span className={CSS.AboutText}>
              About local{' '}
              <Link href={`/host/${local.url}/`} className={CSS.LocalName}>
                {local.name}
              </Link>
            </span>
            <span className={CSS.DestinationDate}>
              {destination}, <span>{date}</span>
            </span>
          </div>
        </div>
      </li>
    </>
  );
}

export default Testimonial;
