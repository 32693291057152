'use client';

import Image from 'next/image';

import CSS from './BCorpImage.module.scss';

const IMAGE_SIZES = [
  { width: 480, height: 240, breakpoint: 991 },
  { width: 592, height: 403 },
];

function imageLoader({ src, width }: { src: string; width: number }) {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,q_auto,w_${width}/${src}`;
}

function BCorpImage() {
  return (
    <div className={CSS.ImageContainer}>
      <Image
        src="sustainability/tect0syqxnihliyaqoah"
        loader={imageLoader}
        className={CSS.BCorpImage}
        alt="Impact report cover image"
        width={214}
        height={300}
        loading="lazy"
      />
    </div>
  );
}

export default BCorpImage;
