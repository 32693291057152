import CSS from '../../../../styles/icons/duoTone.module.scss';
import { DuoToneIconType } from '../../IconType';
import SVGIcon from '../../SVGIcon';

function LocationMarkerIcon(props: DuoToneIconType) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M12.004,2.72 C15.3692145,2.72 18.108,5.45849922 18.108,8.824 C18.108,10.2331384 17.0069466,12.7741017 15.1730986,15.928979 C14.5102522,17.0693129 13.7937113,18.2178124 13.0696282,19.3216245 L12.5134869,20.1584048 L12.0032205,20.9017048 L11.6146579,20.3374669 C11.4204688,20.0512865 11.1898512,19.7063336 10.937548,19.3216548 C10.213596,18.2178681 9.49716221,17.0693536 8.83441938,15.9290099 L8.45286548,15.2625504 C6.84922361,12.4169127 5.9,10.1325049 5.9,8.824 C5.9,5.53293921 8.51779933,2.8420052 11.7805626,2.72403335 L12.004,2.72 Z M12.004,4.509 C9.62370775,4.509 7.688,6.44400742 7.688,8.824 C7.688,11.2041424 9.62385763,13.14 12.004,13.14 C14.3141382,13.14 16.2057417,11.3163417 16.3150141,9.03282162 L16.32,8.824 C16.32,6.44400742 14.3842923,4.509 12.004,4.509 Z"
        data-variant={props.variant}
        className={CSS.Background}
      />
      <path
        d="M11.7805626,2.72403335 C8.51779933,2.8420052 5.9,5.53293921 5.9,8.824 C5.9,10.2331591 7.00087472,12.7741346 8.83441938,15.9290099 C9.49716221,17.0693536 10.213596,18.2178681 10.937548,19.3216548 C11.3916938,20.0140766 11.7755781,20.5777866 12.0032205,20.9017048 L12.0856487,20.7845523 C12.2892009,20.4918033 12.5808119,20.0624873 12.9209828,19.5474532 L13.0696282,19.3216245 C13.7937113,18.2178124 14.5102522,17.0693129 15.1730986,15.928979 C17.0069466,12.7741017 18.108,10.2331384 18.108,8.824 C18.108,5.45849922 15.3692145,2.72 12.004,2.72 L11.7805626,2.72403335 Z M16.3150141,9.03282162 C16.2057417,11.3163417 14.3141382,13.14 12.004,13.14 C9.62385763,13.14 7.688,11.2041424 7.688,8.824 C7.688,6.44400742 9.62370775,4.509 12.004,4.509 C14.3842923,4.509 16.32,6.44400742 16.32,8.824 L16.3150141,9.03282162 Z M15.3147394,8.63619594 C15.2169809,6.89556431 13.769051,5.509 12.004,5.509 C10.1759115,5.509 8.688,6.99637319 8.688,8.824 C8.688,10.6518576 10.1761424,12.14 12.004,12.14 C13.8318576,12.14 15.32,10.6518576 15.32,8.824 L15.3147394,8.63619594 Z M12.2429113,1.72396022 C16.0502109,1.85035333 19.108,4.98614635 19.108,8.824 C19.108,10.4779392 17.9648568,13.1160354 16.0376514,16.431521 C15.3649442,17.5888191 14.6391088,18.7522161 13.9057781,19.8701255 C13.4454943,20.5717946 13.056309,21.143205 12.8225501,21.4758253 L12.0044999,22.643897 L11.5947795,22.0602952 L11.1849186,21.4764933 C10.9514943,21.1443481 10.5616292,20.5718558 10.1013583,19.8700952 C9.36815978,18.7522106 8.64243273,17.588798 7.96983062,16.4314901 C6.04295518,13.1160258 4.9,10.4779226 4.9,8.824 C4.9,4.90585763 8.08585763,1.72 12.004,1.72 L12.2429113,1.72396022 Z"
        data-variant={props.variant}
        className={CSS.Outline}
      />
    </SVGIcon>
  );
}

export default LocationMarkerIcon;
