'use client';

import clsx from 'clsx';
import { ReactNode } from 'react';

import Button from '../Button';
import CSS from './RoundedButton.module.scss';

interface RoundedButtonProps {
  type?: 'button' | 'submit' | 'reset';
  color?: 'pink' | 'white' | 'dark-green' | 'gray';
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  isLoading?: boolean;
  disabled?: boolean;
  children: string | ReactNode;
  [key: string]: any;
}

function RoundedButton({
  type,
  size,
  children,
  isLoading,
  disabled,
  color = 'pink',
  className,
  ...props
}: RoundedButtonProps) {
  const classes = clsx(className, {
    [CSS.RoundedButtonPink]: color === 'pink',
    [CSS.RoundedButtonWhite]: color === 'white',
    [CSS.RoundedButtonDarkGreen]: color === 'dark-green',
    [CSS.RoundedButtonGray]: color === 'gray',
  });

  return (
    <Button
      type={type}
      className={classes}
      isLoading={isLoading}
      shape="rounded"
      size={size}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
}

export default RoundedButton;
