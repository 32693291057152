import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './LocationMarkerIcon.module.scss';

type LocationMarkerIconProps = {
  variant?: 'pink' | 'green' | 'white';
} & IconType;

function LocationMarkerIcon({ variant, ...props }: LocationMarkerIconProps) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M15.82,8.824 C15.82,10.928 14.108,12.64 12.004,12.64 C9.9,12.64 8.188,10.928 8.188,8.824 C8.188,6.72 9.9,5.009 12.004,5.009 C14.108,5.009 15.82,6.72 15.82,8.824 M12.004,2.22 C8.362,2.22 5.4,5.182 5.4,8.824 C5.4,12.314 11.341,20.829 11.594,21.189 L12.004,21.773 L12.413,21.189 C12.666,20.829 18.608,12.314 18.608,8.824 C18.608,5.182 15.645,2.22 12.004,2.22"
        data-variant={variant}
        className={CSS.LocationMarker}
      />
    </SVGIcon>
  );
}

export default LocationMarkerIcon;
