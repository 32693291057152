'use client';

import Image from 'next/image';

import Section from '@/common/components/Section';

import AutoCompleteSearchInput from '../AutoCompleteSearchInput';
import CSS from './LocalsSection.module.scss';

function imageLoader({ src, width }: { src: string; width: number }) {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,q_auto,w_${width}/${src}`;
}

function LocalsSection() {
  return (
    <Section variant="misty-green" noPadding className={CSS.Section}>
      <div className={CSS.Container}>
        <div className={CSS.ImageTopContainer}>
          <div className={CSS.ImageContainer}>
            <Image
              src={'website/homepage/locals-images/local-host-1'}
              alt=""
              className={CSS.TopImage}
              loader={imageLoader}
              width={182}
              height={240}
            />
          </div>
          <div className={CSS.ImageContainer}>
            <Image
              src={'website/homepage/locals-images/local-host-2'}
              alt=""
              className={CSS.TopImage}
              loader={imageLoader}
              width={182}
              height={141}
            />
          </div>
          <div className={CSS.ImageContainer}>
            <Image
              src={'website/homepage/locals-images/local-host-3'}
              alt=""
              className={CSS.TopImage}
              loader={imageLoader}
              width={182}
              height={192}
            />
          </div>
        </div>
        <div className={CSS.ImageBottomContainer}>
          <div className={CSS.ImageContainer}>
            <Image
              src={'website/homepage/locals-images/local-host-4'}
              alt=""
              className={CSS.BottomImage}
              loader={imageLoader}
              width={182}
              height={144}
            />
          </div>
          <div className={CSS.ImageContainer}>
            <Image
              src={'website/homepage/locals-images/local-host-5'}
              alt=""
              className={CSS.BottomImage}
              loader={imageLoader}
              width={182}
              height={242}
            />
          </div>
          <div className={CSS.ImageContainer}>
            <Image
              src={'website/homepage/locals-images/local-host-6'}
              alt=""
              className={CSS.BottomImage}
              loader={imageLoader}
              width={182}
              height={192}
            />
          </div>
        </div>
        <div className={CSS.Content}>
          <h2 className={CSS.Title}>
            Find your <span className={CSS.Highlighted}>own local</span>
          </h2>
          <p className={CSS.Paragraph}>
            Our local hosts can offer you a truly unique tour. Get in contact
            with your favourite local host for an offer 100% personalized to
            your wishes.
          </p>
          <div className={CSS.AutoCompleteContainer}>
            <AutoCompleteSearchInput isLocalSearch />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default LocalsSection;
