'use client';

import { useContext } from 'react';

import ViewAllButton from '@/common/components/buttons/ViewAllButton';
import ExperienceCardCompact from '@/common/components/cards/ExperienceCardCompact';
import CarouselWithButtons from '@/common/components/carousels/CarouselWithButtons';
import ProductListSchema from '@/common/components/schema/ProductListSchema';
import { CurrencyContext } from '@/common/context/CurrencyContext';

function ExperienceList({ experiences }: any) {
  const { state } = useContext(CurrencyContext);
  return (
    <>
      <CarouselWithButtons
        viewAllButton={
          <ViewAllButton href="/experiences/" trackingName={'SeeAllTours'}>
            View all tours
          </ViewAllButton>
        }
      >
        {experiences.map(
          ({
            id,
            title,
            cloudinaryId,
            basePrice,
            formattedPrices,
            duration,
            rating,
            nrOfReviews,
            slug,
            category,
            locals,
          }: any) => (
            <ExperienceCardCompact
              key={`experiencecard-${id}`}
              title={title}
              rating={rating}
              nrOfReviews={nrOfReviews}
              price={basePrice}
              formattedPrice={formattedPrices[state?.currency?.code ?? 'EUR']}
              cloudinaryId={cloudinaryId}
              slug={slug}
              duration={duration}
              category={category}
              locals={locals}
            />
          ),
        )}
      </CarouselWithButtons>
      <ProductListSchema products={experiences} />
    </>
  );
}

export default ExperienceList;
