'use client';

import clsx from 'clsx';

import BadgeWithStarIcon from '@/common/components/icons/badges/duo-tone/BadgeWithStarIcon';
import PersonWithSparkle from '@/common/components/icons/people/duo-tone/PersonWithSparkleIcon';
import { SearchType } from '@/common/types/model/search';

import { sendClickEvent } from '@withlocals/analytics';

import CSS from './SearchTypeToggle.module.scss';

type SearchTypeToggleProps = {
  isModal?: boolean;
  selectedType: SearchType;
  onChange: (type: SearchType) => void;
};

function SearchTypeToggle({
  isModal,
  selectedType,
  onChange,
}: SearchTypeToggleProps) {
  const handleToggle = (type: SearchType) => {
    sendClickEvent({
      trackingName: 'search_type_toggle',
      text: type,
    });
    onChange(type);
  };

  return (
    <div className={CSS.Container} data-modal={isModal}>
      <button
        type="button"
        data-role="search-type"
        className={clsx(CSS.Button, {
          [CSS.Selected]: selectedType === 'experiences',
        })}
        onClick={() => handleToggle('experiences')}
      >
        <BadgeWithStarIcon
          size={16}
          variant={selectedType === 'experiences' ? 'pink' : 'gray'}
        />
        <span>Experiences</span>
      </button>
      <button
        type="button"
        data-role="search-type"
        className={clsx(CSS.Button, {
          [CSS.Selected]: selectedType === 'locals',
        })}
        data-selected={selectedType === 'locals'}
        onClick={() => handleToggle('locals')}
      >
        <PersonWithSparkle
          size={16}
          variant={selectedType === 'locals' ? 'pink' : 'gray'}
        />
        <span>Local guides</span>
      </button>
      <span className={CSS.SelectionIndicator} data-selected={selectedType} />
    </div>
  );
}

export default SearchTypeToggle;
