import clsx from 'clsx';

import CSS from './ModalBody.module.scss';

interface ModalBodyProps {
  className?: string;
  children: any;
  [key: string]: any;
}

function ModalBody({ className, children, ...rest }: ModalBodyProps) {
  return (
    <div className={clsx(CSS.ModalBody, className)} {...rest}>
      {children}
    </div>
  );
}

export default ModalBody;
