'use client';

import ArrowIcon from '@/common/components/icons/symbols/full-color/ArrowIcon';
import LocationMarkerIcon from '@/common/components/icons/symbols/full-color/LocationMarkerIcon';

import TrackedLink from '@withlocals/analytics/TrackedLink';

import CSS from './GridControls.module.scss';

interface GridControlsProps {
  destination: string;
  url: string;
  onPrevClick: () => void;
  onNextClick: () => void;
}

function GridControls({
  destination,
  url,
  onPrevClick,
  onNextClick,
}: GridControlsProps) {
  return (
    <div className={CSS.GridControls}>
      <button
        className={CSS.Button}
        onClick={onPrevClick}
        aria-label="Previous destination"
      >
        <ArrowIcon
          size={16}
          variant="dark-green"
          direction="left"
          className={CSS.Icon}
        />
      </button>
      <div className={CSS.TextContainer}>
        <TrackedLink
          href={url}
          className={CSS.Link}
          trackingName={`ReadMore-${destination}`}
          aria-label={`Explore ${destination}`}
        >
          <LocationMarkerIcon
            size={20}
            variant="green"
            className={CSS.MarkerIcon}
          />{' '}
          {destination}
        </TrackedLink>
      </div>
      <button
        className={CSS.Button}
        onClick={onNextClick}
        aria-label="Next destination"
      >
        <ArrowIcon
          size={16}
          variant="dark-green"
          direction="right"
          className={CSS.Icon}
        />
      </button>
    </div>
  );
}

export default GridControls;
