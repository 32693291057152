'use client';

import lscache from 'lscache';
import { useEffect, useState } from 'react';

import stringToKebabCase from '@/common/helpers/textHelpers/stringToKebabCase';

import { sendSearchEvent } from '@withlocals/analytics';

import fetchDestinations from './fetchDestinations';

interface DestinationSearchProps {
  searchTerm: string;
  highlightedDestinations: any;
  isLocalSearch?: boolean;
}

type DestinationResultsType = {
  type: string;
  title: string;
  subTitle: string;
  url: string;
  isNew: boolean;
};

const recentlyViewed = lscache.get('recently-viewed-experiences') ?? [];

const recentlyViewedTours =
  recentlyViewed.map((tour: any) => ({
    type: 'tour',
    title: tour.title,
    subTitle: `${tour.attributes.subCategory}, ${tour.attributes.area}`,
    url: `/experience/${tour.pretty_url}`,
  })) || [];

const recentSearches = lscache.get('recent_searches') ?? [];

const recentlySearchedDestinations =
  recentSearches.map((destination: any) => {
    const urlParts = ['s', stringToKebabCase(destination.country)];

    if (destination.city) {
      urlParts.push(stringToKebabCase(destination.city));
    }

    return {
      type: 'destination',
      title: destination.city ? destination.city : destination.country,
      subTitle: destination.city ? destination.country : 'country',
      url: '/' + urlParts.join('/'),
    };
  }) || [];

const recentlyViewedItems = [
  ...recentlyViewedTours,
  ...recentlySearchedDestinations,
];

function useDestinationSearch({
  searchTerm,
  highlightedDestinations,
  isLocalSearch,
}: DestinationSearchProps): DestinationResultsType[] {
  const [results, setResults] = useState(
    !isLocalSearch ? recentlyViewedItems : [],
  );

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      let isFresh = true;
      fetchDestinations({ searchTerm, isLocalSearch }).then((destinations) => {
        if (isFresh) {
          setResults(destinations);
          sendSearchEvent({
            searchTerm: searchTerm.trim(),
            numberOfResults: destinations.length,
          });
        }
      });
      return () => {
        isFresh = false;
      };
    } else {
      setResults([]);
    }
  }, [searchTerm, isLocalSearch]);

  if (!searchTerm) {
    const popularDestinations =
      highlightedDestinations?.map((destination: any) => ({
        type: 'destination',
        title: destination.city.name,
        subTitle: destination.country.name,
        url:
          '/' +
          [
            isLocalSearch ? 'l' : 's',
            destination.country.slug,
            destination.city.slug,
            isLocalSearch ? 'guides' : '',
          ]
            .filter(Boolean)
            .join('/') +
          '/',
      })) || [];

    if (results.length > 0) {
      return results;
    }

    if (!isLocalSearch && recentlyViewedItems.length > 0) {
      return recentlyViewedItems;
    }

    return popularDestinations;
  }

  return results;
}

export default useDestinationSearch;
