import clsx from 'clsx';
import Link from 'next/link';

import LocationMarkerIcon from '@/common/components/icons/symbols/duo-tone/LocationMarkerIcon';
import ChevronIcon from '@/common/components/icons/symbols/full-color/ChevronIcon';
import CameraIcon from '@/common/components/icons/travel-tourism/duo-tone/CameraIcon';
import MapWithMarkerIcon from '@/common/components/icons/travel-tourism/duo-tone/MapWithMarkerIcon';

import CSS from './SearchResultListItem.module.scss';

interface SearchResultListItemProps {
  type: string;
  title: string;
  subTitle: string;
  isHighlighted: boolean;
  url: string;
  isNew: boolean;
}

function SearchResultListItem({
  type,
  title,
  subTitle,
  isHighlighted,
  url,
  isNew,
}: SearchResultListItemProps) {
  return (
    <li
      className={clsx(CSS.SearchResultListItem, {
        [CSS.HighlightedItem]: isHighlighted,
      })}
    >
      <div className={CSS.IconContainer}>
        {type === 'destination' && (
          <LocationMarkerIcon
            size={24}
            variant={isHighlighted ? 'pink' : 'black'}
          />
        )}
        {type === 'tour' && (
          <MapWithMarkerIcon
            height={24}
            variant={isHighlighted ? 'pink' : 'black'}
          />
        )}
        {type === 'point-of-interest' && (
          <CameraIcon size={24} variant={isHighlighted ? 'pink' : 'black'} />
        )}
      </div>
      <div>
        <div className={CSS.TitleContainer}>
          <Link href={url} className={CSS.Title}>
            {title}
          </Link>
          {isNew && <div className={CSS.NewBadge}>New</div>}
        </div>
        <span className={CSS.SubTitle}>{subTitle}</span>
      </div>

      <div>
        <ChevronIcon
          size={16}
          className={CSS.ArrowIcon}
          variant={isHighlighted ? 'pink' : 'black'}
        />
      </div>
    </li>
  );
}

export default SearchResultListItem;
