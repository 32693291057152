type ImageSizeType = {
  width: number;
  height: number;
  breakpoint?: number;
};

interface PictureElementProps {
  imageSizes: ImageSizeType[];
  cloudinaryId: string;
  alt: string;
  className: string;
  isThumbnail?: boolean;
  loading?: 'eager' | 'lazy';
  [key: string]: any;
}

const getFallbackImage = (
  cloudinaryId: string,
  width: number,
  height: number,
  isThumbnail?: boolean,
) => {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_${
    isThumbnail ? 'thumb,z_0.6' : 'fill'
  },f_auto,g_faces,q_auto,w_${width},h_${height}/${cloudinaryId}`;
};

const getSrcSet = (
  cloudinaryId: string,
  width: number,
  height: number,
  isThumbnail?: boolean,
) => {
  const urlStart = `https://withlocals-com-res.cloudinary.com/image/upload/c_${
    isThumbnail ? 'thumb,z_0.6' : 'fill'
  },f_auto,g_faces,q_auto,w_${width},h_${height}`;
  return `${urlStart},dpr_1.0/${cloudinaryId}, ${urlStart},dpr_2.0/${cloudinaryId} 2x`;
};

function PictureElement({
  imageSizes,
  cloudinaryId,
  alt,
  className,
  isThumbnail,
  loading,
  ...props
}: PictureElementProps) {
  const backupSize = imageSizes[0];
  const nrOfSizes = imageSizes.length;
  return (
    <picture className={className} {...props}>
      {imageSizes.map((imageSize, index) => {
        const isLast = index === nrOfSizes - 1;
        return (
          <source
            key={index}
            media={
              isLast
                ? `(min-width: ${Math.max(
                    Number(imageSizes[index - 1]?.breakpoint) + 1,
                    0,
                  )}px)`
                : `(max-width: ${imageSize.breakpoint}px)`
            }
            srcSet={getSrcSet(
              cloudinaryId,
              imageSize.width,
              imageSize.height,
              isThumbnail,
            )}
            width={imageSize.width}
            height={imageSize.height}
          />
        );
      })}
      <img
        src={getFallbackImage(
          cloudinaryId,
          backupSize.width,
          backupSize.height,
          isThumbnail,
        )}
        alt={alt}
        width={backupSize.width}
        height={backupSize.height}
        className={className}
        loading={loading}
      />
    </picture>
  );
}

export default PictureElement;
