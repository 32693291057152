import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './SearchGlassIcon.module.scss';

type SearchGlassIconProps = {
  variant?: 'default' | 'dark-green';
} & IconType;

function SearchGlassIcon({ variant, ...props }: SearchGlassIconProps) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M9.13888889,2 C11.1018518,2 12.7731482,2.69444444 14.1527777,4.08333333 C15.5324074,5.47222222 16.2222222,7.14814815 16.2222222,9.11111111 C16.2222222,9.90740741 16.0925926,10.6759259 15.8333333,11.4166667 C15.5740741,12.1574074 15.1851852,12.8518518 14.6666667,13.5 L22,20.7777777 L20.7777777,22 L13.4722222,14.6944444 C12.9166667,15.1759259 12.2685185,15.5509259 11.5277777,15.8194444 C10.787037,16.087963 10,16.2222222 9.16666667,16.2222222 C7.16666667,16.2222222 5.47222222,15.5277778 4.08333333,14.1388889 C2.69444444,12.75 2,11.0740741 2,9.11111111 C2,7.14814815 2.69444444,5.47222222 4.08333333,4.08333333 C5.47222222,2.69444444 7.15740741,2 9.13888889,2 Z"
        data-variant={variant}
        className={CSS.Frame}
      />
      <path
        d="M9.13888889,3.66666667 C7.62037037,3.66666667 6.3287037,4.19907407 5.26388889,5.26388889 C4.19907408,6.3287037 3.66666667,7.61111111 3.66666667,9.11111111 C3.66666667,10.6111111 4.19907408,11.8935185 5.26388889,12.9583333 C6.3287037,14.0231482 7.62037037,14.5555556 9.13888889,14.5555556 C10.6388889,14.5555556 11.9166667,14.0231482 12.9722223,12.9583333 C14.0277778,11.8935185 14.5555556,10.6111111 14.5555556,9.11111111 C14.5555556,7.61111111 14.0277778,6.3287037 12.9722223,5.26388889 C11.9166667,4.19907407 10.6388889,3.66666667 9.13888889,3.66666667 Z"
        data-variant={variant}
        className={CSS.Glass}
      />
      <path
        d="M15.6970022,15.952664 L15.9792789,15.6800726 C16.3762003,15.29677 16.9579945,15.2533631 17.2775471,15.5842695 L21.8743212,19.949133 C22.1946494,20.2808425 22.131928,20.8598371 21.7340432,21.2440701 L21.0143822,21.9390385 C20.6174608,22.3223411 20.03663,22.3648176 19.7163018,22.0331081 L15.556912,17.2458675 C15.2373594,16.9149611 15.3000808,16.3359665 15.6970022,15.952664 Z"
        data-variant={variant}
        className={CSS.Handle}
      />
    </SVGIcon>
  );
}

export default SearchGlassIcon;
