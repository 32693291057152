'use client';

import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';

import PictureElement from '@/common/components/PictureElement';

import CSS from './MediaCard.module.scss';

interface MediaCardProps {
  variant?: string;
  cloudinaryId: string;
  alt: string;
  isVideo?: boolean;
  onVideoEnd?: () => void;
}

const IMAGE_SIZES = [
  [
    { width: 132, height: 249, breakpoint: 449 }, // up to 450w
    { width: 137, height: 234, breakpoint: 767 }, // up to 768w
    { width: 184, height: 285 },
  ],
  [
    { width: 219, height: 337, breakpoint: 449 }, // up to 450w
    { width: 183, height: 316, breakpoint: 767 }, // up to 768w
    { width: 184, height: 285 },
  ],
  [
    { width: 132, height: 249, breakpoint: 449 }, // up to 450w
    { width: 137, height: 234, breakpoint: 767 }, // up to 768w
    { width: 184, height: 184 },
  ],
];

function MediaCard({
  variant,
  cloudinaryId,
  alt,
  isVideo,
  onVideoEnd,
}: MediaCardProps) {
  const imageSizes = useMemo(() => {
    if (variant === 'middle') {
      return IMAGE_SIZES[1];
    }
    if (variant === 'square') {
      return IMAGE_SIZES[2];
    }
    return IMAGE_SIZES[0];
  }, [variant]);

  return (
    <div className={CSS.MediaCard}>
      <AnimatePresence initial={false}>
        <motion.figure
          className={CSS.Figure}
          key={cloudinaryId}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
        >
          {isVideo && (
            <video
              autoPlay
              muted
              playsInline
              className={CSS.Video}
              onEnded={onVideoEnd}
            >
              <source
                src={`https://withlocals-com-res.cloudinary.com/video/upload/w_184,h_285,g_center,c_fill,q_auto,br_250k/f_mp4/${cloudinaryId}.mp4`}
                type="video/mp4"
              />
              <source
                src={`https://withlocals-com-res.cloudinary.com/video/upload/w_184,h_285,g_center,c_fill,q_60:qmax_90,br_250k/f_webm/${cloudinaryId}.webm`}
                type="video/webm"
              />
            </video>
          )}
          {!isVideo && (
            <PictureElement
              cloudinaryId={cloudinaryId}
              alt={alt}
              imageSizes={imageSizes}
              className={CSS.Image}
              priority="true"
            />
          )}
        </motion.figure>
      </AnimatePresence>
    </div>
  );
}

export default MediaCard;
