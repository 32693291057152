'use client';

import Image from 'next/image';
import { useMemo } from 'react';

import CSS from './LocalImagesWithCount.module.scss';

function imageLoader({ src, width }: { src: string; width: number }) {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,q_auto,g_face,ar_1,c_thumb,z_0.6,w_${width}/${src}`;
}

interface LocalImagesWithCountProps {
  hostImageIds: string[];
  nrOfHosts: number;
  color: 'pink' | 'green';
}

function LocalImagesWithCount({
  hostImageIds,
  nrOfHosts,
  color,
}: LocalImagesWithCountProps) {
  const { hostImages, hostNumber } = useMemo(() => {
    const hostImages = hostImageIds.slice(0, 2);
    const hostNumber = nrOfHosts - 2;
    return { hostImages, hostNumber };
  }, [hostImageIds, nrOfHosts]);
  return (
    <div className={CSS.LocalImagesContainer}>
      {hostImages.map((imageId) => (
        <Image
          key={`host-image-${imageId}`}
          src={imageId}
          loader={imageLoader}
          width={44}
          height={44}
          alt="Host image"
          className={CSS.LocalImage}
        />
      ))}
      {hostNumber > 0 && (
        <span className={CSS.NrOfHosts} data-color={color}>
          +{hostNumber}
        </span>
      )}
    </div>
  );
}

export default LocalImagesWithCount;
