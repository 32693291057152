import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import CSS from './SearchResultPopover.module.scss';

function SearchResultPopover({
  children,
  isModal,
}: {
  children: ReactNode | JSX.Element;
  isModal?: boolean;
}) {
  const classes = clsx(CSS.SearchResultPopover, {
    [CSS.SearchResultPopoverModal]: isModal,
  });
  return (
    <motion.div
      className={classes}
      initial={{ opacity: 0, scaleY: 0, originY: 0, y: 10 }}
      animate={{ opacity: 1, scaleY: 1, originY: 0, y: 0 }}
      transition={{ duration: 0.14, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  );
}

export default SearchResultPopover;
