'use client';

import Image from 'next/image';

import CSS from './CardImage.module.scss';

interface CardImageProps {
  cloudinaryId: string;
  cardNr: number;
}

function imageLoader({ src, width }: { src: string; width: number }) {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,q_auto,w_${width}/${src}`;
}

function CardImage({ cloudinaryId, cardNr }: CardImageProps) {
  return (
    <div className={CSS.CardImageContainer} data-cardnumber={cardNr}>
      <Image
        src={cloudinaryId}
        loader={imageLoader}
        width={388}
        height={cardNr === 1 ? 444 : 200}
        alt=""
        className={CSS.CardImage}
      />
    </div>
  );
}

export default CardImage;
