import CSS from '../../../../styles/icons/duoTone.module.scss';
import { DuoToneIconType } from '../../IconType';
import SVGIcon from '../../SVGIcon';

function CameraIcon(props: DuoToneIconType) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M4.2708611,7.89579007 C5.19241736,8.35844348 5.85,9.54967942 5.85,10.94 C5.85,11.22 6.07,11.44 6.35,11.44 C6.63,11.44 6.85,11.22 6.85,10.94 C6.85,9.56147676 7.49647037,8.3786739 8.40573701,7.90772443 L20.14,7.91 C20.64,7.91 21.04,8.31 21.04,8.81 L21.04,19.15 C21.04,19.64 20.64,20.05 20.14,20.05 L3.86,20.05 C3.36,20.05 2.96,19.65 2.96,19.15 L2.96,8.81 C2.96,8.32 3.36,7.91 3.86,7.91 L4.16,7.91 C4.19834625,7.91 4.23556717,7.90587378 4.2708611,7.89579007 Z M6.35,5.65 C6.67,6.3 7.13,6.83 7.67,7.19 C7.13,7.55 6.67,8.08 6.35,8.73 C6.03,8.08 5.57,7.55 5.03,7.19 C5.57,6.83 6.03,6.3 6.35,5.65 Z M13.94,4.88 L13.94,6.57 L9.99,6.57 L9.99,4.88 L13.94,4.88 Z"
        data-variant={props.variant}
        className={CSS.Background}
      />
      <path
        d="M6.35,2.94 C6.63,2.94 6.85,3.16 6.85,3.44 C6.85,5.09936335 7.78670627,6.47513674 8.99093614,6.66711015 L8.99,4.38 C8.99,4.1 9.21,3.88 9.49,3.88 L14.44,3.88 C14.72,3.88 14.94,4.1 14.94,4.38 L14.94,6.909 L20.14,6.91 C21.19,6.91 22.04,7.76 22.04,8.81 L22.04,19.15 C22.04,20.2 21.19,21.05 20.14,21.05 L3.86,21.05 C2.81,21.05 1.96,20.2 1.96,19.15 L1.96,8.81 C1.96,8.10072581 2.34785433,7.48271146 2.92321364,7.1563063 L2.92,7.19 C2.92,6.91 3.14,6.69 3.42,6.69 C4.76,6.69 5.85,5.23 5.85,3.44 C5.85,3.16 6.07,2.94 6.35,2.94 Z M6.85,10.94 C6.85,11.22 6.63,11.44 6.35,11.44 C6.07,11.44 5.85,11.22 5.85,10.94 C5.85,9.54967942 5.19241736,8.35844348 4.2708611,7.89579007 C4.23556717,7.90587378 4.19834625,7.91 4.16,7.91 L3.86,7.91 C3.36,7.91 2.96,8.32 2.96,8.81 L2.96,19.15 C2.96,19.65 3.36,20.05 3.86,20.05 L20.14,20.05 C20.64,20.05 21.04,19.64 21.04,19.15 L21.04,8.81 C21.04,8.31 20.64,7.91 20.14,7.91 L8.40573701,7.90772443 C7.49647037,8.3786739 6.85,9.56147676 6.85,10.94 Z M11.96,8.94 C14.73,8.94 16.97,11.19 16.97,13.95 C16.97,16.71 14.72,18.96 11.96,18.96 C9.19,18.96 6.94,16.71 6.94,13.95 C6.94,11.19 9.19,8.94 11.96,8.94 Z M11.96,9.93 C9.75,9.93 7.94,11.73 7.94,13.94 C7.94,16.15 9.74,17.95 11.96,17.95 C14.18,17.95 15.97,16.15 15.97,13.94 C15.97,11.73 14.17,9.93 11.96,9.93 Z M11.96,10.84 C13.67,10.84 15.07,12.24 15.07,13.95 C15.07,15.66 13.67,17.06 11.96,17.06 C10.24,17.06 8.85,15.66 8.85,13.95 C8.85,12.24 10.25,10.84 11.96,10.84 Z M11.96,11.84 C10.79,11.84 9.85,12.79 9.85,13.95 C9.85,15.11 10.8,16.06 11.96,16.06 C13.12,16.06 14.07,15.11 14.07,13.95 C14.07,12.79 13.12,11.84 11.96,11.84 Z M6.35,5.65 C6.03,6.3 5.57,6.83 5.03,7.19 C5.57,7.55 6.03,8.08 6.35,8.73 C6.67,8.08 7.13,7.55 7.67,7.19 C7.13,6.83 6.67,6.3 6.35,5.65 Z M13.94,4.88 L9.99,4.88 L9.99,6.57 L13.94,6.57 L13.94,4.88 Z"
        data-variant={props.variant}
        className={CSS.Outline}
      />
    </SVGIcon>
  );
}

export default CameraIcon;
