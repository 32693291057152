'use client';

import Image from 'next/image';

import CSS from './CardImage.module.scss';

function imageLoader({ src, width }: { src: string; width: number }) {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,q_auto,w_${width}/${src}`;
}

function CardImage({
  cloudinaryId,
  alt,
}: {
  cloudinaryId: string;
  alt: string;
}) {
  return (
    <Image
      src={cloudinaryId}
      alt={alt}
      loader={imageLoader}
      width={388}
      height={313}
      className={CSS.Image}
    />
  );
}

export default CardImage;
