'use client';

import Image from 'next/image';

import CSS from './HostAndGuestImages.module.scss';

interface HostAndGuestImagesProps {
  local: {
    image: string;
    name: string;
  };
  guest: {
    image: string;
    name: string;
  };
}

function imageLoader({ src, width }: { src: string; width: number }) {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,ar_1,f_auto,q_auto,w_${width}/${src}`;
}

function HostAndGuestImages({ local, guest }: HostAndGuestImagesProps) {
  return (
    <div className={CSS.ImageContainer}>
      <Image
        src={local.image}
        width={56}
        height={56}
        alt={`local ${local.name}`}
        loader={imageLoader}
        className={CSS.Image}
      />
      <Image
        src={guest.image}
        width={56}
        height={56}
        alt={`guest ${guest.name}`}
        loader={imageLoader}
        className={CSS.Image}
      />
    </div>
  );
}

export default HostAndGuestImages;
