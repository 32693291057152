import { useMedia } from 'react-use';

import screen from 'common-toolbox/src/styles/screen-sizes.module.scss';

const DEFAULT_MEDIA_STATE = false; // default state is used on the server. Mobile is preferred for the server.

function useIsMobile(breakPoint: string = screen.sm): boolean {
  const isLargerScreen = useMedia(
    `(min-width: ${breakPoint})`,
    DEFAULT_MEDIA_STATE,
  );

  return !isLargerScreen;
}

export default useIsMobile;
