import { ReactNode } from 'react';

import { SearchType } from '@/common/types/model/search';

import CSS from './SearchResultList.module.scss';
import SearchTypeToggle from './SearchTypeToggle';

interface SearchResultListProps {
  promptText: string;
  searchType: SearchType;
  isModal?: boolean;
  onChange: (type: SearchType) => void;
  children: ReactNode;
}

function SearchResultList({
  promptText,
  children,
  searchType,
  isModal,
  onChange,
}: SearchResultListProps) {
  return (
    <div className={CSS.SearchResultList}>
      <div className={CSS.Header}>
        <h3 className={CSS.ListTitle}>{promptText}</h3>
        {!isModal && (
          <SearchTypeToggle selectedType={searchType} onChange={onChange} />
        )}
      </div>
      <ul className={CSS.List}>{children}</ul>
    </div>
  );
}

export default SearchResultList;
