'use client';

import Image from 'next/image';

import CSS from './CardImage.module.scss';

interface CardImageProps {
  cloudinaryId: string;
  city: {
    slug: string;
    name: string;
  };
  country: {
    slug: string;
    name: string;
  };
}

function imageLoader({ src, width }: { src: string; width: number }) {
  return `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,g_faces,ar_1.27,w_${width}/${src}`;
}

function CardImage({ cloudinaryId, city, country }: CardImageProps) {
  return (
    <div className={CSS.CardImageContainer}>
      <Image
        src={cloudinaryId}
        alt={`Things to do in ${city.name}, ${country.name}`}
        loader={imageLoader}
        width={388}
        height={304}
        className={CSS.CardImage}
      />
    </div>
  );
}

export default CardImage;
