import { Product, WithContext } from 'schema-dts';

import { TestimonialProps } from './Testimonial';

function TestimonialMetaData({
  title,
  review,
  local,
  rating,
  date,
  destination,
  guest,
  product,
}: TestimonialProps) {
  const jsonLd: WithContext<Product> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    sku: product.sku,
    name: product?.title ?? `${destination} tour with ${local.name}`,
    review: {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        worstRating: 0.5,
        bestRating: 5,
        ratingValue: rating,
      },
      name: title,
      reviewBody: review,
      datePublished: date,
      author: {
        '@type': 'Person',
        name: guest.name,
      },
    },
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
}

export default TestimonialMetaData;
