import clsx from 'clsx';
import { ReactNode } from 'react';

import CSS from './ImageGridContainer.module.scss';

interface ImageGridContainerProps {
  children: ReactNode;
  hasVideo: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
}

let startPos = 0;
const SWIPE_THRESHOLD = 150;

function ImageGridContainer({
  children,
  hasVideo,
  onNextClick,
  onPrevClick,
}: ImageGridContainerProps) {
  const classes = clsx(CSS.ImageGrid, {
    [CSS.ImageGridWithVideo]: hasVideo,
  });

  const onTouchStart = (e: any) => {
    startPos = e.touches[0].clientX;
  };

  const onTouchMove = (e: any) => {
    e.preventDefault();
    const endPos = e.touches[0].clientX;
    const diffPos = endPos - startPos;
    if (diffPos > SWIPE_THRESHOLD) {
      onPrevClick();
    }
    if (diffPos < -SWIPE_THRESHOLD) {
      onNextClick();
    }
  };

  return (
    <div
      className={CSS.GridContainer}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
    >
      <div className={classes}>{children}</div>
    </div>
  );
}

export default ImageGridContainer;
