'use client';

interface Args {
  searchTerm: string;
  isLocalSearch?: boolean;
}

const cache: any = {};
function fetchDestinations({ searchTerm, isLocalSearch }: Args) {
  const key = `${searchTerm}-${isLocalSearch}`;
  if (cache[key]) {
    return Promise.resolve(cache[key]);
  }
  return fetch(
    `/storefront-api/search/destination/autocomplete/?query=${searchTerm}&limit=10&localsearch=${
      isLocalSearch ? 'true' : 'false'
    }`,
  )
    .then((res) => res.json())
    .then((result) => {
      cache[key] = result;
      return result;
    });
}

export default fetchDestinations;
