'use client';

import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { KeyboardEvent } from 'react';

import { SearchType } from '@/common/types/model/search';

import SearchInput from './SearchInput';
import SearchResultList from './SearchResultList';
import SearchResultListItem from './SearchResultListItem';
import SearchResultPopover from './SearchResultPopover';
import SearchTypeToggle from './SearchTypeToggle';
import useDestinationSearch from './hooks/useDestinationSearch';

interface SearchInputFormProps {
  highlightedDestinations?: any[];
  isLocalSearch?: boolean;
  isDisabled?: boolean;
  isModal?: boolean;
  onSearchTypeChange?: (type: SearchType) => void;
}

function getPromptText(isModal: boolean, searchType: SearchType) {
  if (!isModal) {
    return 'Search for';
  }
  switch (searchType) {
    case 'experiences':
      return 'Experiences in:';
    case 'locals':
      return 'Local guides in:';
  }
}

function SearchInputForm({
  highlightedDestinations,
  isLocalSearch,
  isDisabled,
  isModal = false,
  onSearchTypeChange,
}: SearchInputFormProps) {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [showList, onListToggle] = useState(false);
  const [searchType, setSearchType] = useState<SearchType>(
    isLocalSearch ? 'locals' : 'experiences',
  );
  const handleSearchTypeChange = (type: SearchType) => {
    setSearchType(type);
    onSearchTypeChange?.(type);
  };
  const destinations = useDestinationSearch({
    searchTerm,
    highlightedDestinations,
    isLocalSearch: searchType === 'locals',
  });
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(0);
  const handleSearchTermChange = (newSearchTerm: string) =>
    setSearchTerm(newSearchTerm);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (showList || searchTerm?.trim() !== '') {
      const selectedDestination = destinations[highlightedOptionIndex];
      if (selectedDestination) {
        router.push(selectedDestination.url);
      }
    }
  };

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setHighlightedOptionIndex(
        Math.min(highlightedOptionIndex + 1, destinations.length - 1),
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setHighlightedOptionIndex(Math.max(highlightedOptionIndex - 1, 0));
    }
  }

  const promptText = getPromptText(isModal, searchType);

  return (
    <form onSubmit={onSubmit}>
      <SearchInput
        onChange={handleSearchTermChange}
        onKeyDown={handleKeyDown}
        onInputFocus={onListToggle}
        disabled={isDisabled}
        isModal={isModal}
        isLocalSearch={isLocalSearch}
      />
      {isModal && (
        <SearchTypeToggle
          isModal
          selectedType={searchType}
          onChange={handleSearchTypeChange}
        />
      )}
      {(showList || isModal) && destinations.length > 0 && (
        <SearchResultPopover isModal={isModal}>
          <SearchResultList
            isModal={isModal}
            promptText={promptText}
            searchType={searchType}
            onChange={handleSearchTypeChange}
          >
            {destinations.map((item, index) => (
              <SearchResultListItem
                key={item.url}
                type={item.type}
                title={item.title}
                subTitle={item.subTitle}
                isHighlighted={index === highlightedOptionIndex}
                url={item.url}
                isNew={item.isNew}
              />
            ))}
          </SearchResultList>
        </SearchResultPopover>
      )}
    </form>
  );
}

export default SearchInputForm;
